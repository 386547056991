export const Bio = {
  name: "Armin Rezaei",
  roles: ["Front-end developer", "Programmer"],
  description:
    "I am an enthusiastic and adaptable individual, constantly seeking out new challenges. Fueled by a passion for continuous learning, I am committed to delivering top-notch results. Infused with a positive attitude and a growth mindset, I am poised to make a meaningful impact and achieve remarkable accomplishments. I am eager to take on your project, and I am ready to deliver exceptional results at a reasonable and budget-friendly price.",
  github: "https://github.com/ArminRezaeii",
  resume: "../../CV/Armin-CV.pdf",
  linkedin: "https://www.linkedin.com/in/armin-rezaei-96615a277/",
  twitter: "https://twitter.com/ArminRezaeeii",
};
<></>;
export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "Next js",
        image: "https://cdn.worldvectorlogo.com/logos/next-js.svg",
      },
      {
        name: "Shadcn ui",
        image:
          "https://pbs.twimg.com/media/FxoIFVgagAE-gqB?format=png&name=4096x4096",
      },
      {
        name: "React Js",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },

      {
        name: "React Query",
        image:
          "https://seeklogo.com/images/R/react-query-logo-1340EA4CE9-seeklogo.com.png",
      },
      {
        name: "Redux",
        image:
          "https://d33wubrfki0l68.cloudfront.net/0834d0215db51e91525a25acf97433051f280f2f/c30f5/img/redux.svg",
      },
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
      {
        name: "Material UI",
        image: "https://mui.com/static/logo.png",
      },
      {
        name: "TypeScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/2048px-Typescript_logo_2020.svg.png",
      },
      {
        name: "Tailwind",
        image:
          "https://logowik.com/content/uploads/images/tailwind-css3232.logowik.com.webp",
      },
      {
        name: "GraphQL",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/GraphQL_Logo.svg/2048px-GraphQL_Logo.svg.png",
      },
    ],
  },

  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image: "https://git-scm.com/images/logos/downloads/Git-Icon-1788C.png",
      },
      {
        name: "GitHub",
        image:
          "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
      },
      {
        name: "Postman",
        image: "https://cdn.worldvectorlogo.com/logos/postman.svg",
      },
      {
        name: "Sanity",
        image: "https://www.sanity.io/static/images/logo_rounded_square.png",
      },
      {
        name: "VS Code",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
      },
      {
        name: "Figma",
        image:
          "https://squidex.it-blue.net/api/assets/blue-it/143f1aea-d5ea-4434-b44f-c99f1641728d",
      },
      {
        name: "Firebase",
        image:
          "https://cdn.icon-icons.com/icons2/2699/PNG/512/firebase_logo_icon_171157.png",
      },
    ],
  },
];

export const experiences = [
  
];

// export const education = [
//   {
//     id: 0,
//     img: "https://upload.wikimedia.org/wikipedia/commons/b/b1/IAUTMU_Logo_%28cropped%29.png",
//     school: "Azad University of yadegar emam",
//     date: "Oct 2021 - Sep 2025",
//     desc: "I am currently studying computer engineering at the undergraduate level.",
//   },
// ];

export const projects = [
  {
    id: 1,
    title: "React-Slack-Clone",
    description:
      "Explore our website, a massage and communication platform authentically designed with React, Firebase, Redux, Styled Components, TypeScript, and Material-UI. Connect securely with Google authentication, upload images, create private rooms, and engage in real-time conversations with friends. Immerse yourself in a tech-savvy, visually stunning, and innovative online experience.",
    image: "assets/images/62540c4a83ecf31d4fb3c9a9_Group 5650.png",
    tags: ["React", "Redux", "TypeScript", "Mui", "Firebase"],
    github: "https://github.com/ArminRezaeii/React-Slack",
    webapp: "https://snazzy-malabi-452118.netlify.app/",
  },
  {
    id: 2,
    title: "cloudy",
    description:
      "Developed a file storage website utilizing Next.js, Convex, and Clerk, demonstrating proficiency in full-stack development. This project highlights my capability to create efficient and secure platforms for file management.",
    image: "assets/images/image_2024-03-10_17-20-41.png",
    tags: ["Next 14", "Convex", "TypeScript", "Clerk"],
    github: "https://github.com/ArminRezaeii/Cloudy",
    webapp: "https://cloudy-pied.vercel.app/",
  },
  {
    id: 3,
    title: "iPhone 15",
    description:
      "Created an iPhone 15 website clone using Next.js, Three.js, and GASP.",
    image: "assets/images/image_2024-03-23_16-53-33.png",
    tags: ["Next 14", "TypeScript", "Three.js", "GSAP"],
    github: "https://github.com/ArminRezaeii/Apple-Website-Clone",
    webapp: "https://i-phone15-website-clone.vercel.app/",
  },
  {
    id: 4,
    title: "Personal Blog Next14",
    description:
      "Welcome to my personal blog, where I've used the latest Next.js 14 and spruced it up with the chic Shadcn UI , powered by the versatile CMS Sanity. Take a stroll through my thoughts and stories—it's a cozy corner of the internet. The site is all about a smooth mix of tech and design, making your reading experience just as enjoyable as the content",
    image: "assets/images/blogImage.png",
    tags: ["Next 14", "Shadcn ui", "TypeScript", "Taillwind", "Sanity"],
    github: "",
    webapp: "https://armin-blog-iota.vercel.app/",
  },
  {
    id: 5,
    title: "Pricewise",
    description:
      "Developed a Price Tracker web application using Next.js 14, TypeScript, Tailwind, and MongoDB. The application scrapes Amazon product data, displays detailed information, and tracks prices. Implemented email notifications for users when the product reaches their desired price. Check out the project on GitHub for more details. ",
    image: "assets/images/pricewise.png",
    tags: ["Next 14", "MongoDB", "TypeScript", "Tailwind"],
    github: "https://github.com/ArminRezaeii/Pricewise",
    webapp: "https://pricewise-mu-cyan.vercel.app/",
  },
  {
    id: 321,
    title: "React-Github-Search",
    description:
      "GitHub-Search GitHub-Search is a web app that lets you search for GitHub users and see their name, followers, and repos. It is built with Tailwind CSS, JavaScript, React, and React Query. It is open-source and welcomes contributions.",
    image: "assets/images/photo_2023-12-23_01-42-27.jpg",
    tags: ["React", "ReactQuery", "JavaScript", "Taillwind"],
    github: "https://github.com/ArminRezaeii/GitHub-search",
    webapp: "https://dancing-sunburst-882563.netlify.app/",
  },
  {
    id: 32,
    title: "ReactJS-Spotify-Clone",
    description:
      "Introducing our ReactJS Spotify Clone: a sleek, user-friendly project where you can seamlessly authenticate with your Spotify account, load your profile, and dive into your music library. Enjoy easy play/pause controls, real-time updates, and responsive design for a personalized and immersive listening experience. Elevate your music journey with this intuitive recreation of the Spotify interface.",
    image:
      "https://assets.turbologo.com/blog/en/2021/07/20045641/Spotify_logo_symbol.png",
    tags: ["React", "ReactQuery", "JavaScript", "Taillwind"],
    github: "https://github.com/ArminRezaeii/React-music",
    webapp: "",
  },
  {
    id: 12,
    title: "ReactJS-Cryptocurrency",
    description:
      "Explore the dynamic world of cryptocurrencies with this comprehensive Cryptocurrency Dashboard built using cutting-edge web technologies. Our project seamlessly integrates ReactJSChart, React-Redux, and the sleek design of Ant Design JS to deliver an intuitive and feature-rich experience for cryptocurrency enthusiasts and investors.",
    image: "assets/images/image_2023-12-25_01-31-13.png",
    tags: ["React", "Redux", "JavaScript", "ant design"],
    github: "https://github.com/ArminRezaeii/React-Cryptocurrency",
    webapp: "https://precious-paprenjak-8df76c.netlify.app/",
  },
  {
    id: 543,
    title: "EasyWeather - A Simple React PWA",
    description:
      "Step into a new era of weather exploration with ClimaVue, a state-of-the-art React-based web application designed to deliver unparalleled weather details seamlessly. More than just a weather platform, ClimaVue harnesses the power of Progressive Web App (PWA) technology, ensuring you have the latest meteorological insights at your fingertips anytime, anywhere..",
    image: "assets/images/photo_2023-12-27_15-04-17.jpg",
    tags: ["React", "JavaScript", "Html & Css"],
    github: "https://github.com/ArminRezaeii/EasyWeather---A-Simple-React-PWA",
    webapp: "https://fantastic-liger-5c2eb9.netlify.app/",
  },
  {
    id: 64,
    title: "Job Seeker App",
    description:
      "CareerHub is not just a job finder; it's a comprehensive career companion, designed to empower you in every step of your professional journey. Experience the future of job exploration - download CareerHub today and step into a world where your dream job is just a click away.",
    image: "assets/images/image_2024-01-10_01-09-35.png",
    tags: ["React", "TypeScript", "Html", "Tailwind", "Redux"],
    github: "https://github.com/ArminRezaeii/React-Job-Finder",
    webapp: "https://lucky-taffy-e54fc8.netlify.app/",
  },
  {
    id: 43,
    title: "React-3D",
    description:
      "Welcome to my 3D Web Apparel Creator! This interactive website, built using TypeScript, React, HTML, Tailwind CSS, Three.js, Valtio, and Framer Motion, allows users to customize virtual t-shirts. Upload your logo, experiment with various t-shirt colors, and visualize your unique design in a dynamic 3D environment. Dive into the world of personalized fashion with this cutting-edge web app.",
    image: "assets/images/image_2024-01-11_03-44-15.png",
    tags: [
      "React",
      "TypeScript",
      "Html",
      "Tailwind",
      "Three.js",
      "Valtio",
      "Framer Motion",
    ],
    github: "https://github.com/ArminRezaeii/React-Three-JS",
    webapp: "https://timely-dolphin-191cf5.netlify.app/",
  },
  {
    id: 567,
    title: "React-Shop",
    description:
      "About A web development project using Redux Toolkit, React Router DOM, Tailwind CSS, React, and JavaScript.",
    image: "assets/images/photo_2023-12-17_20-39-01.jpg",
    tags: ["React", "Redux", "JavaScript", "Taillwind"],
    github: "https://github.com/ArminRezaeii/React-Shop",
    webapp: "https://verdant-melba-b51067.netlify.app/",
  },
];
